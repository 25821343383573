<template>
  <div class="be-card h-100">
    <header-with-divider
      :text="$t('transaction.transfers_list')"
      color="blue"
    />
    <div
      class="d-flex align-items-center justify-content-between p-4 contentTop"
    >
      <BaseInputSearch :api-search="true" @search="search" />
      <button
        :class="{
          'i-left': !smallSize,
          'circle-btn': smallSize,
        }"
        class="be-btn primary green lg"
        @click="$router.push({ name: 'transfer.form' })"
      >
        <i class="icon-plus" />
        {{ !smallSize ? $t("transaction.new_transfer") : "" }}
      </button>
    </div>
    <div class="divider" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && transfers && transfers.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="transfers"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template v-slot:status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : statusClass(row.status),
              smallSize ? '' : 'status',
            ]"
          >
            {{ $t(`status.${row.status.toLowerCase()}`) }}
          </span>
        </template>
        <template v-slot:created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
        <template v-slot:amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span>{{ row.amount + " " + row.currency }}</span>
            <span v-if="!smallSize" class="f-12">
              <i
                :class="[
                  operationIcon(getTransferOperatorType(row)),
                  operationClass(getTransferOperatorType(row)),
                ]"
                class="op-icon"
              />
              {{
                $t(`status.type.${getTransferOperatorType(row).toLowerCase()}`)
              }}
            </span>
          </div>
        </template>
        <template v-slot:operation-type="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span>
              <i
                :class="[
                  operationIcon(getTransferOperatorType(row)),
                  operationClass(getTransferOperatorType(row)),
                ]"
                class="op-icon"
              />

              {{
                $t(`status.type.${getTransferOperatorType(row).toLowerCase()}`)
              }}
            </span>
          </div>
        </template>
        <template v-slot:desc="{ row }">
          <div class="d-flex flex-column f-w-600">
            <span class="f-14">{{
              $t(`common.${row.is_receiver ? "received_from" : "sent_to"}`)
            }}</span>
            <span class="f-10 description text-truncate">
              {{ row.user.email }}
            </span>
          </div>
        </template>
        <template v-slot:reference="{ row }">
          <div class="d-flex flex-column">
            {{ row.reference }}
          </div>
        </template>
        <template v-slot:actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <BasePagination
        v-if="transfers.length && last_page > 1 && !loading"
        :current_page="current_page"
        :last_page="last_page"
        @page="changePage"
      />
    </div>
    <no-content
      v-else
      :text="$t('transaction.no_transaction_at_the_moment')"
      icon="icon-transfer"
    ></no-content>
    <b-modal ref="detailsModal" no-fade size="lg" top>
      <div class="text-center" v-if="modalData">
        <b-row>
          <b-col>
            <div class="label-text">
              <div>
                {{
                  $t(
                    `transaction.${
                      modalData.is_receiver ? "sender" : "receiver_1"
                    }`
                  )
                }}
              </div>
              <div class="text-capitalize">
                {{ userName || $t("transaction.no_specified") }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>
                {{
                  $t(
                    `transaction.${
                      modalData.is_receiver
                        ? "sender_1_email"
                        : "receiver_1_email"
                    }`
                  )
                }}
              </div>
              <div>
                {{ modalData.user.email }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ amountLabel }}</div>
              <div>
                {{ `${modalData.amount} ${modalData.currency}` }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <div class="label-text">
              <div>{{ $t("common.status") }}</div>
              <div :class="statusColorClass(modalData.status)">
                {{ $t(`status.${modalData.status.toLowerCase()}`) }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text">
              <div>{{ $t("common.creation_date") }}</div>
              <div>
                {{ modalData.created_at | moment("Do MMMM YYYY H:mm:ss") }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="label-text" v-if="modalData.is_receiver">
              <div>{{ $t("common.reference") }}</div>
              <div>
                {{ modalData.reference }}
              </div>
            </div>
            <div class="label-text" v-else>
              <div>{{ $t("transaction.transfer_fee") }}</div>
              <div>
                {{ `${modalData.fee_amount} ${modalData.currency}` }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <template slot="modal-header" v-if="modalData">
        <span />
        <span class="modal-title">
          {{ $t("transaction.transfer_details") }} -
          {{ modalData.reference }}
        </span>
        <span @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <template slot="modal-footer" v-if="modalData">
        <template v-if="transferIsAvailableForTicket">
          <span class="f-w-600 fermer mr-4" @click="hideModal">
            {{ $t("common.close") }}
          </span>
          <button
            class="ml-4 be-btn primary lg danger"
            @click="$router.push({ name: 'assistance' })"
          >
            {{ $t("common.create_a_ticket") }}
          </button>
        </template>
        <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
          {{ $t("common.close") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import BeTable from "@/components/BeTable";
import Loading from "@/components/common/Loading";
import NoContent from "@/components/common/NoContent";
import { OperationClassMixin } from "@/mixins/OperationClassMixin";
import { isFailed, isPending, movementTypeCode } from "@/helpers/constants";

export default {
  name: "TransferList",
  mixins: [OperationClassMixin],
  components: { HeaderWithDivider, BeTable, Loading, NoContent },
  data() {
    return {
      loading: true,
      transferField: {
        page: 1,
        keyword: null,
      },
      modalData: null,
      fields: [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ],
      smallTableFields: [
        {
          label: this.$t("common.operation_type"),
          key: "operation-type",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
          class: "break-all",
        },
        {
          label: "Description",
          key: "desc",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("transfer", ["transfers", "current_page", "last_page"]),
    ...mapGetters("auth", ["isCurrentUser"]),
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
    transferIsAvailableForTicket() {
      return (
        this.modalData &&
        (isPending(this.modalData.status) || isFailed(this.modalData.status))
      );
    },
    userName() {
      return this.modalData
        ? `${this.modalData.user.first_name ?? ""} ${this.modalData.user
            .last_name ?? ""}`.trim()
        : null;
    },
    amountLabel() {
      return this.modalData
        ? this.$t(
            `transaction.${
              this.modalData.is_receiver ? "received_amount" : "send_amount"
            }`
          )
        : null;
    },
  },

  created() {
    this.getTransferList();
  },

  methods: {
    getTransferList() {
      this.loading = true;
      this.$store
        .dispatch("transfer/getTransferList", this.transferField)
        .finally(() => {
          this.loading = false;
        });
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    search(text) {
      this.transferField.keyword = text;
      this.getTransferList();
    },
    changePage(page) {
      this.transferField.page = page;
      this.getTransferList();
    },
    getTransferOperatorType(row) {
      return row.is_receiver ? movementTypeCode.credit : movementTypeCode.debit;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  display: inline-block;
  max-width: 155px;
}

.hovered:hover .icon-arrow-right {
  display: block;
}

.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

.fermer {
  cursor: pointer;
}

/deep/ .modal-backdrop {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(3px) !important;
}

.contentTop {
  @media (max-width: 500px) {
    padding: 1rem !important;
  }
}
</style>
